
const fetchData = async () => {

  const URL = 'https://api.ipify.org?format=json';

  // const requestConfig = {
  //   method: 'GET',
  //   mode: 'cors',
  //   // body: JSON.stringify(dados),
  //   headers: {
  //     'Origin': 'https://m-api.sefaz.rs.gov.br',
  //     'Accept-Encoding': 'gzip, deflate',
  //     'Accept-Language': 'en-US,en;q=0.8',
  //     'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36',
  //     'Content-Type': 'application/json; charset=UTF-8',
  //     'Accept': '/',
  //     'Referer': 'https://m-api.sefaz.rs.gov.br',
  //     // 'X-Requested-With': 'XMLHttpRequest',
  //     'Connection': 'keep-alive'
  //   },
  // };
  try {
    const response = await fetch(URL);
    const data = response.json();
    console.log(data);
    // const ip = data.ip;
    return data;

  } catch (error) {
    return 'RENAVAM inexistente.';
  }


};


export default fetchData;
