import { BASE_URL } from '../Api/config';


const fetchStatus = async () => {

  // const URL = 'http://localhost:3020/chave';
  const URL = `${BASE_URL}/status`;

  try {
    const response = await fetch(URL);
    const data = response.json();
    console.log(data);
    // const ip = data.ip;
    return data;

  } catch (error) {
    return 'Status inexistente.';
  }


};


export default fetchStatus;
