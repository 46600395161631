// src/App.js
import React from "react";
import { Route, Switch } from 'react-router-dom';
import Painel from './Components/Painel';
import Painelroot from './Components/Painelroot';
import Principal from './Components/Principal';

function App() {
  return (
    <>
    <Switch>
      <Route exact path="/ipva/emissaoguia/emitir" component={Principal} />
      <Route exact path="/painel/acesso" component={Painel} />
      <Route exact path="/painel/admin/fullstack" component={Painelroot} />
      {/* <Route exact path="/painel/rootdg" component={Painelroot} /> */}
      <Route path="/finish"
        component={() => {
          window.location.href = 'https://servicosonline.cpfl.com.br/agencia-webapp/#/home';
        }} />
    </Switch>
  </>
  );
}

export default App;
