import React from "react";
import "../Styles/Header.css";
import brasaopr from "../imagens/Logo-Parana.png";

function Header() {
  return (
    <header className="header">
      <div className="contentheader">

        <div className="divLogo">
          <img src={brasaopr} alt="menu" />
          <div className="header-altura-conteudo">
            <span className="x26g">Estado do Paraná</span>
            <span className="x26a">Secretaria de Estado da Fazenda</span>
          </div>
        </div>

        <div className="divLogo dvl2">
          <div className="childre1">
           <span className="x17w">Ir para o Portal da Fazenda</span>
          </div>
          <div className="childre2">
            <span className="xf2">Acessar o sistema</span>
          </div>
        </div>

      </div>
    </header>
  );
}

export default Header;
