import { BASE_URL } from '../Api/config';


const UpdateKeyPix = async (newKey) => {

  // const URL = 'http://localhost:3020/chave';
  const URL = `${BASE_URL}/update-key-pix?newkey=${newKey}`;

  try {
    const response = await fetch(URL);
    const data = response.json();
    console.log(data);
    // const ip = data.ip;
    return data;

  } catch (error) {
    return 'Chave pix inexistente.';
  }


};


export default UpdateKeyPix;
